.our-team-page-team {
    .team-style-04 {
        figure {
            figcaption {
                .title-bottom {
                    .title {
                        color: #232323;
                    }
                }
            }
        }
    }
}