/* Transition */
:root {
    --default-transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
    --alt-font: 'poppins', sans-serif;
    --primary-font: 'Roboto', sans-serif;
    --viewport-height: 100vh;
    --xl: 1600px;
    --lg: 1199px;
    --md: 991px;
    --sm: 767px;
    --xs: 575px;
    --xxs: 480px;
}