header {
    .spasalon-header {
        .navbar-collapse {
            &.mobile-menu-modern {
                background: #232323;
            }
        }
    }
}

.text-overlap {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}

.spasalon-slider {
    .btn-slider-prev,
    .btn-slider-next {
        > div {
            transition: var(--default-transition);

            i {
                transition: transform 0.3s ease-in-out;
            }
        }

        &:hover {
            > div {
                color: #ff8089;
            }
        }
    }

    .btn-slider-prev {
        i {
            margin-right: 10px;
        }

        &:hover {
            i {
                transform: translateX(-10px);
            }
        }
    }

    .btn-slider-next {
        i {
            margin-left: 10px;
        }

        &:hover {
            i {
                transform: translateX(10px);
            }
        }
    }
}

.spa-salon-swiper {
    .swiper-pagination-fractional {
        .swiper-pagination-bullets {
            &.swiper-pagination-horizontal {
                bottom: 80px;
            }
        }
    }

    .swiper-pagination {
        @apply left-[360px] absolute hidden xs:flex md:left-0 md:justify-center;

        .swiper-pagination-bullet {
            opacity: 0.5;
            left: 0px;
            top: 0px;
            color: #fff;
            font-family: Poppins, sans-serif;
            font-size: 15px;
            margin-right: 20px;
            width: 20px;
            &.swiper-pagination-bullet-active {
                opacity: 1;
            }
        }
    }
}
