.seoagency-iconwithtext {
  .icon-with-text-01 {
    p {
      width: 85%;
    }

    .feature-box-content {
      .title {
        margin-bottom: 5px !important;
      }
    }
  }
}
.seo-agency-footer_06 {
  div {
    span {
        color:#374162 ;
        text-transform: capitalize;
    }
    p{
      a{
        color:#374162 ;
      }
    }
  }
}
