.instagram-posts {
  figure {
    img {
      @apply  w-full;
    }

    i {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      background: rgba(35, 35, 35, 0.7);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #fff;
      transition: var(--default-transition);
    }

    &:hover {
      i {
        opacity: 1;
      }
    }
  }
}